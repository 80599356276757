import PageLoader from '../../layouts/PageLoader';
import * as constant from '../../constant';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';


export default () => {
    const { auth } = useSelector((state) => state)
    return (
        <section className="row m-0" style={{ height: "100vh" }}>
            <div className="col-lg-6 col-md-12 login-gradient d-none d-lg-block px-0">
                <div className="d-flex justify-content-center align-items-center h-100">
                    {auth?.isLoading && <PageLoader />}
                    <div className="mx-5 w-75">
                        <h1 className="text-light">Welcome Back!</h1>
                        <h3 className="text-light">Please Sign in to your account.</h3>
                        <p>Hey! Please check out this site later. We are doing
                        some maintenance on it right now.
                            </p>

                        <div className="w-100 py-4">
                            <img src={constant.login_banner} className=" w-100 h-100 rounded" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center col-lg-6 col-md-12 px-0" >
                <Outlet />
            </div>
        </section>
    )
}