import React from "react";


const Table = ({ children, header, thead, footer  }) => {

  return (
    <div className="card table-card">

      {header && <div className="card-header">
        {header}
      </div>}

      <div className="card-body ">
        <div className="table-responsive">
          <table className="table table-hover" id="pc-dt-simple">
            <thead>
              <tr>
                {thead?.map((t) => <th style={{ width: t?.width, textAlign: t?.text, fontSize: t?.fontSize }}>{t?.title}</th>)}
              </tr>
            </thead>
            <tbody>
              {children}
            </tbody>
          </table>
        </div>
      </div>

      {footer && <div className="card-footer">
        {footer}
      </div>}

    </div>
  );
};

export default Table;
