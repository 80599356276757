import React, { memo, useEffect, useId, useRef, useState } from 'react';
import OutsideWrapper from '../OutsideWrapper'
import Spinner from './Spinner';

const AutoComplete = ({
    label,
    options = [],
    position = "top",
    loading = false,
    optionLabel = (value) => value,
    optionValue = (value) => value,
    showValue = (value) => value,
    onChange = () => '',
    onSelect = () => '',
    clearOnSelect=false,
    ...props
}) =>  {


    const [isDropdown, setIsDropdown] = useState(false)
    const dropdown = useRef(null)
    const inputRef = useRef(null)
    const id = useId()


    
    useEffect(() => {
        if( isDropdown ){
            window.addEventListener('keydown', eventHandler)
        } else { 
            window.removeEventListener('keydown', eventHandler)
        }

        return(() => window.removeEventListener('keydown', eventHandler))
    }, [options])




    const eventHandler = (e) => {
        try {
            if (!dropdown || !["ArrowUp", "ArrowDown", "Enter"].includes(e.key)) return

            const activeElem = dropdown.current?.querySelector(".dropdown-active")

            let i = +activeElem?.id?.split('-')[1]

            const KEY_CODES = {
                "ArrowUp": scrollUp,
                "ArrowDown": scrollDown,
                "Enter": onEnter
            }

            KEY_CODES[e.key](i, e)

        } catch (error) { console.error(error) }
    }



    const scrollUp = (i) => {
        if( isNaN(i) ) i = options.length - 1 
        else if( i ){
            dropdown.current.children[i]?.classList?.remove("dropdown-active")
            i -= 1
        }

        autoScroll(i)
        dropdown.current.children[i]?.classList?.add("dropdown-active")
    }



    const scrollDown = (i) => {
        console.log('options', options)
        if( isNaN(i) ) i = 0
        else if( i === 0 || i ){
            dropdown.current.children[i]?.classList?.remove("dropdown-active")
            i += 1
        }

        autoScroll(i)
        dropdown.current.children[i]?.classList?.add("dropdown-active")
    }



    const onEnter = (i, e) => {
        if( isNaN(i) ) return
        selectValue(optionValue(options[i]), showValue(options[i]))
        setIsDropdown(false)
        inputRef.current.blur()
    }



    const autoScroll = (i) => {
        dropdown.current.scrollTop = i * dropdown.current.children[0].clientHeight
    }



    const handleChange = (e) => {
        setIsDropdown(!!e.target.value)

        dropdown.current?.querySelector(".dropdown-active")?.classList?.remove("dropdown-active")
        const index = options.findIndex(a => showValue(a).toLowerCase().includes(e.target.value.toLowerCase()))

        if (index !== -1) {
            dropdown.current.children[index].classList?.add("dropdown-active")
            autoScroll(index)
        }

        onChange(e.target.value)
    }


    const selectValue = ( value, showValue ) => {
        if( clearOnSelect ) inputRef.current.value = ''
        else inputRef.current.value = showValue


        onChange( value )
        onSelect( value )
    }

    return (
        <OutsideWrapper callback={() => setIsDropdown(false)}>

            <div className='position-relative' id={id}>

                {!!options.length && <div className={`bg-white p-2 rounded position-absolute shadow w-100 ${!isDropdown && "d-none"} `} style={{ [position === "top" ? "bottom" : "top"] : "100%" }}>
                    <div id='dropdown' ref={dropdown} >
                        
                        {options.map((value, index) =>
                            <p href id={`list-${index}`}
                                className={`p-2 my-1 dropdown-list`}
                                key={index}
                                onClick={() => {
                                    selectValue(optionValue(value), showValue(value))
                                    setIsDropdown(false)
                                }}>
                                {optionLabel(value)}
                            </p>
                        )}

                    </div>
                    <div className='bg-white text-center pt-2'>
                        <Spinner loading={loading} >{""}</Spinner>
                    </div>
                </div>}

                <div className="mb-3">
                    {label&& <label className="form-label">{label}</label>}
                    <input
                        id={id}
                        type='search'
                        autoComplete='off'
                        ref={inputRef}
                        className={`form-control`}
                        onChange={handleChange}
                        {...props}
                    />
                </div>

            </div>
        </OutsideWrapper>
    );
}

export default memo(AutoComplete);