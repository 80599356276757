export const dateFormate = (date) =>{
    const formatedDate = new Date(date)
    return formatedDate.toLocaleDateString()
}

export const FileToUrl = ( file ) => URL.createObjectURL(file)

export const FormHeader = {
    headers: {
        "Content-Type": "multipart/form-data"
    }
}

export const downloadFileFromBlob = ( blob, name ) => {
    const url = URL.createObjectURL( blob )

    const link = document.createElement("a")
    link.href = url
    link.download = name
    link.click()

    URL.revokeObjectURL( url )
}

export const NumberInWords = (num) => {
    if (num === 0) return "zero";

    const ones = ["", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine"];
    const teens = ["ten", "eleven", "twelve", "thirteen", "fourteen", "fifteen", "sixteen", "seventeen", "eighteen", "nineteen"];
    const tens = ["", "", "twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"];
    const thousands = ["", "thousand", "lakh", "crore"];

    function convertChunk(num) {
        let str = '';
        if (num >= 100) {
            str += ones[Math.floor(num / 100)] + " hundred ";
            num %= 100;
        }
        if (num >= 20) {
            str += tens[Math.floor(num / 10)] + " ";
            num %= 10;
        }
        if (num >= 10) {
            str += teens[num - 10] + " ";
        } else if (num > 0) {
            str += ones[num] + " ";
        }
        return str.trim();
    }

    function convertLakhCrore(num) {
        if (num === 0) return "";
        let str = '';
        let crore = Math.floor(num / 10000000);
        let lakh = Math.floor((num % 10000000) / 100000);
        let thousand = Math.floor((num % 100000) / 1000);
        let remainder = num % 1000;

        if (crore > 0) {
            str += convertChunk(crore) + "crore ";
        }
        if (lakh > 0) {
            str += convertChunk(lakh) + "lakh ";
        }
        if (thousand > 0) {
            str += convertChunk(thousand) + "thousand ";
        }
        if (remainder > 0) {
            str += convertChunk(remainder);
        }
        return str.trim();
    }

    let result = convertLakhCrore(num);
    return result.trim();
}

export const convertGender = ( gender ) => {
    const genders = { F: "Female", M: "Male", O: "Others" }
    return genders[gender]
}